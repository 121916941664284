<template>
<div class="loginInfo">
    <Header :activeIndex="7"></Header>
    <div class="loginInfo_con" :style="{minHeight: (minHeight - 330) + 'px'}">
        <img class="loginInfo_logo" src="@/assets/images/officialWebsite/login.png" alt="传奇云游戏">
        <el-form :model="userForm" status-icon :rules="rules" ref="userForm" class="loginInfo_form" onsubmit="return false;">
            <div class="loginInfo_content">
                <div class="loginInfo_icon">
                    <img src="@/assets/images/officialWebsite/icon_login.png" alt="传奇云游戏">
                    <p>注册并登录</p>
                </div>
                <div class="login-error-show-msg" v-if="errMsg">{{errMsg}}</div>
                <div class="login-error-hide-msg" v-else></div>
                <el-form-item prop="phone" class="loginInfo_phone">
                    <el-input v-model.trim="userForm.phone" autocomplete="on" type="telephone" name="phone" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item prop="verification_code" class="loginInfo_code">
                    <el-input v-model.trim="userForm.verification_code" autocomplete="on" name="code" placeholder="请输入验证码" class="code_input"></el-input>
                    <button @click="getauthCode()" :disabled="counttime >= 0" :class="{active: counttime >= 0 || buttonType}">{{showGetCode}}</button>
                </el-form-item>
                <el-form-item class="loginInfo_btn">
                    <!--<el-button @click="submitForm('userForm')" native-type="submit">立即登录</el-button>-->
                    <button @click="submitForm('userForm')">立即登录</button>
                </el-form-item>
                <section>点击「立即登录」按钮，即代表你同意 <router-link to="/auth/agreement" id="user-agreement"><span>《用户协议》</span></router-link>
                </section>
            </div>
        </el-form>
    </div>
    <Footer></Footer>
</div>
</template>

<script>
import commonRequest from '@/utils/common'
export default {
    name: "Login",
    data() {
        var checkPhoneNum = (rule, value, callback) => {
            let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            this.buttonType = true
            if (!value) {
                return callback(new Error('手机号码不能为空'));
            }
            if (!Number.isInteger(Number(value))) {
                return callback(new Error('手机号码只支持数字'));
            } else {
                if (!reg_tel.test(value)) {
                    // this.buttonType = true
                    return callback(new Error('请输入正确的手机号'));
                } else {
                    this.buttonType = false
                    callback();
                }
            }
        }
        var checkVerCode = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('验证码不能为空'));
            } else {
                if (!Number.isInteger(Number(value))) {
                    return callback(new Error('验证码只支持数字'));
                } else {
                    let codeLength = value.length;
                    if (codeLength != 6 && codeLength != 4) {
                        return callback(new Error('请输入正确的验证码'));
                    } else {
                        callback();
                    }
                }
            }
        };
        return {
            active: 'active',
            buttonType: false,
            minHeight: 0,
            sendCodeStatus: false, // 是否点击发送验证码按钮
            userForm: {
                phone: '', // 手机号
                verification_code: '', // 验证码
                verification_key: '', // 验证码key
                appid: "cn.dygame.web",
                mac: "web"
            },
            counttime: -1,
            codeinterval: '',
            errMsg: '',
            rules: {
                verification_code: [{
                    validator: checkVerCode,
                    trigger: 'change'
                }],
                phone: [{
                    validator: checkPhoneNum,
                    trigger: 'change'
                }]
            }
        };
    },
    computed: {
        showGetCode() {
            if (this.counttime >= 0) {
                return this.counttime + 's' + "后重新发送";
            } else {
                return "获取验证码"
            }
        }
    },
    created() {
        this.$store.state.login.user ? this.$router.replace({
            name: "index",
            query: {
                redirect: this.$router.currentRoute.fullPath
            }
        }) : null
    },
    beforeDestroy() {
        clearInterval(this.codeinterval);
        this.codeinterval = "";
        this.counttime = -1;
    },
    mounted() {
        this.minHeight = document.documentElement.clientHeight;
        window.onresize = () => {
            this.minHeight = document.documentElement.clientHeight;
        }
    },
    watch: {},
    methods: {
        getauthCode() {
            this.sendCodeStatus = true;
            this.$refs.userForm.validateField('phone', errorMessage => {
                if (!errorMessage) {
                    let data = {
                        phone: this.userForm.phone,
                    };
                    this.$API.sendVerifyCode(data)
                        .then((response) => {
                            this.errMsg = '';
                            let res = response.data;
                            if (res.status == 1) {
                                this.userForm.verification_key = res.data.key;
                                this.counttime = 60;
                                this.codeinterval = setInterval(() => {
                                    this.counttime--;
                                    if (this.counttime == -1) {
                                        clearInterval(this.codeinterval);
                                        this.codeinterval = "";
                                        this.counttime = -1;
                                    }
                                }, 1000);
                            }
                        })
                }
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                this.errMsg = '';
                if (valid && this.userForm.verification_key) {
                    this.$API.loginAuth(this.userForm)
                        .then((response) => {
                            let res = response.data;
                            if (res && res.status == 1) {
                                const user = res.data
                                this.$store.dispatch("login", user);
                                this.$router.push(this.$route.query.redirect || '/index')
                                // 获取用户新消息
                                commonRequest.get_user_msg_list();
                            }
                        })
                } else {
                    if (!this.sendCodeStatus && this.userForm.verification_code) {
                        this.$store.commit('MESSAGE_TEXT','请先获取验证码');
                    }
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
#user-agreement {
    text-decoration: none;
}

.login-error-hide-msg {
    height: 24px;
    line-height: 24px;
}

.login-error-show-msg {
    height: 40px;
    line-height: 40px;
    color: red;
    font-size: 12px;
}

/*屏幕大于1200px*/
@media screen and (min-width: 1200px) {
    .loginInfo {
        .loginInfo_con {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .loginInfo_logo {
                width: 550px;
                height: 500px;
            }

            .loginInfo_form {
                width: 550px;
                height: 500px;
                background: #ffffff;
                margin-right: 40px;

                .loginInfo_content {
                    width: 72%;
                    margin: 55px 75px;

                    .loginInfo_icon {
                        height: 40px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        //margin-bottom: 24px;

                        img {
                            width: 40px;
                            height: 40px;
                            margin-right: 5px;
                        }

                        p {
                            margin-top: 10px;
                            color: #888888;
                            font-size: 16px;
                            letter-spacing: 2px;
                        }
                    }

                    .loginInfo_phone {
                        width: 100%;
                        height: 56px;
                        margin-bottom: 30px;
                    }

                    .loginInfo_code {
                        .code_input {
                            width: 220px;
                            margin-right: 24px;
                        }

                        button {
                            outline: none;
                            width: 146px;
                            height: 54px;
                            border: 1px solid #D69D4D;
                            color: #D69D4D;
                            background: #FFFAF4;
                        }
                    }

                    .loginInfo_btn {
                        margin: 48px 0;

                        button {
                            outline: none;
                            width: 100%;
                            height: 56px;
                            background: #D69D4D;
                            color: #FFFFFF;
                            border: none;
                        }

                        button:hover {
                            background: #c38f41;
                        }
                    }

                    section {
                        color: #3A3F4F;
                        font-size: 16px;

                        span {
                            cursor: pointer;
                            color: #D69D4D;
                        }
                    }
                }
            }
        }
    }
}

/*屏幕小于1200px大于900px*/
@media screen and (max-width: 1200px) and (min-width: 900px) {
    .loginInfo {
        .loginInfo_con {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .loginInfo_logo {
                width: 400px;
                height: 500px;
                /*margin-right: 20px;*/
            }

            .loginInfo_form {
                width: 450px;
                height: 500px;
                background: #ffffff;
                margin-right: 40px;

                .loginInfo_content {
                    width: 72%;
                    margin: 55px 75px;

                    .loginInfo_icon {
                        height: 40px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        //margin-bottom: 24px;

                        img {
                            width: 40px;
                            height: 40px;
                            margin-right: 5px;
                        }

                        p {
                            margin-top: 10px;
                            color: #888888;
                            font-size: 16px;
                            letter-spacing: 2px;
                        }
                    }

                    .loginInfo_phone {
                        width: 100%;
                        margin-bottom: 30px;
                    }

                    .loginInfo_code {
                        .code_input {
                            width: 200px;
                            margin-right: 24px;
                        }

                        button {
                            outline: none;
                            width: 100px;
                            height: 54px;
                            border: 1px solid #D69D4D;
                            color: #D69D4D;
                            background: #FFFAF4;
                        }
                    }

                    .loginInfo_btn {
                        margin: 48px 0;

                        button {
                            outline: none;
                            width: 100%;
                            height: 56px;
                            background: #D69D4D;
                            color: #FFFFFF;
                            border: none;
                        }

                        button:hover {
                            background: #c38f41;
                        }
                    }

                    section {
                        color: #3A3F4F;
                        font-size: 16px;

                        span {
                            cursor: pointer;
                            color: #D69D4D;
                        }
                    }
                }
            }
        }
    }
}

/*屏幕小于900px大于550px*/
@media screen and (max-width: 900px) and (min-width: 550px) {
    .loginInfo {
        .loginInfo_con {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .loginInfo_logo {
                width: 550px;
                height: 500px;
            }

            .loginInfo_form {
                width: 550px;
                height: 500px;
                background: #ffffff;

                .loginInfo_content {
                    width: 72%;
                    margin: 55px 75px;

                    .loginInfo_icon {
                        height: 40px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        //margin-bottom: 24px;

                        img {
                            width: 40px;
                            height: 40px;
                            margin-right: 5px;
                        }

                        p {
                            margin-top: 10px;
                            color: #888888;
                            font-size: 16px;
                            letter-spacing: 2px;
                        }
                    }

                    .loginInfo_phone {
                        width: 100%;
                        margin-bottom: 30px;
                    }

                    .loginInfo_code {
                        .code_input {
                            width: 220px;
                            margin-right: 24px;
                        }

                        button {
                            outline: none;
                            width: 146px;
                            height: 54px;
                            border: 1px solid #D69D4D;
                            color: #D69D4D;
                            background: #FFFAF4;
                        }
                    }

                    .loginInfo_btn {
                        margin: 48px 0;

                        button {
                            outline: none;
                            width: 100%;
                            height: 56px;
                            background: #D69D4D;
                            color: #FFFFFF;
                            border: none;
                        }

                        button:hover {
                            background: #c38f41;
                        }
                    }

                    section {
                        color: #3A3F4F;
                        font-size: 16px;

                        span {
                            cursor: pointer;
                            color: #D69D4D;
                        }
                    }
                }
            }
        }
    }
}

/*屏幕小于550px大于300px*/
@media screen and (max-width: 549px) {
    .loginInfo {
        .loginInfo_con {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            >img{
                display: none;
            }
            .loginInfo_logo {
                width: 100%;
                height: 500px;
            }

            .loginInfo_form {
                width: 90%;
                background: #ffffff;

                .loginInfo_content {
                    width: 72%;
                    margin: 55px auto;

                    .loginInfo_icon {
                        height: 40px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        //margin-bottom: 24px;

                        img {
                            width: 40px;
                            height: 40px;
                            margin-right: 5px;
                        }

                        p {
                            margin-top: 10px;
                            color: #888888;
                            font-size: 16px;
                            letter-spacing: 2px;
                        }
                    }

                    .loginInfo_phone {
                        width: 100%;
                        margin-bottom: 30px;
                    }

                    .loginInfo_code {
                        width: 100%;

                        button {
                            outline: none;
                            width: 100%;
                            height: 54px;
                            border: 1px solid #D69D4D;
                            color: #D69D4D;
                            background: #FFFAF4;
                            margin-top: 24px;
                        }
                    }

                    .loginInfo_btn {
                        margin: 48px 0;

                        button {
                            outline: none;
                            width: 100%;
                            height: 56px;
                            background: #D69D4D;
                            color: #FFFFFF;
                            border: none;
                        }

                        button:hover {
                            background: #c38f41;
                        }
                    }

                    section {
                        color: #3A3F4F;
                        font-size: 16px;
                        margin-bottom: 24px;

                        span {
                            cursor: pointer;
                            color: #D69D4D;
                        }
                    }
                }
            }
        }
    }
}

.loginInfo {
    width: 100%;
    background: #F0EFEE !important;

    .loginInfo_con {
        height: 100%;

        .active {
            border: 1px solid #ADADB0 !important;
            color: #ADADB0 !important;
            background: #F0EFEE !important;
        }

        /deep/ .el-input__inner {
            height: 56px;
        }

        /deep/ .el-input__inner:focus {
            border-color: #D69D4D;
            box-shadow: none;
        }

        button {
            border-radius: 4px;
        }
    }
}
</style>
